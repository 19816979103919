import { env } from "../env/env";
export const LOGO_URL = env.CDN_URL + "/receipt/logo/";
export const IMAGE_URL = env.CDN_URL + "/receipt/image/";
export const IMAGE_DEBTSETTLEMENTBILL_URL = env.CDN_URL + "/receipt/debtSettlementBill/";
export const THUMBNAIL_URL = env.CDN_URL + "/receipt/thumbnail/";
export const PRODUCT_GROUP_URL = "core/productGroup";
export const PRODUCT_ITEM_URL = "core/productItem";
export const CUSTOMER_URL = "core/customers";
export const STAFF_URL = "core/staffs";
export const TRUCK_URL = "core/trucks";
export const FIELDSTAFFGROUP_URL = "core/fieldStaff";
export const PLANFIELDSTAFF_URL = "core/planFieldStaff";
export const PLANFIELDGROUP_URL = "core/fsGroup";
export const PLANFIELDPLAN_URL = "core/fsPlan";
export const CUSTOMER_BRANCHES_URL = "core/customers/branches";
export const ROLE_FIELD_STAFF_URL = "core/staffs/roleFieldStaff/available";
export const ROLE_FIELD_STAFF_SEARCH_URL = "core/staffs/roleFieldStaff";
export const NOTI_TOKEN_URL = "core/staffToken";
export const NOTI_URL = "core/notification";
export const NOTI_READ_URL = "core/notification/read";
export const CONTRACT_NUMBER_URL = "core/contracts/reminder";
export const CONTRACT_REMIND_URL = "core/staffs/remind";
export const ACCOUNTCUSTOMER_URL = "core/staffs/customerAccount";
export const DEPARTMENT_URL = "hrm/department";
export const PLANNING_URL = "core/plannings";
export const ENUM_URL = "core/common/enum";
export const BRANCHES_URL = "core/customers/branches";
export const CONTRACT_URL = "core/contracts";
export const CONTRACT_PROGRESS_URL = "core/contractProgress";
export const RECEIVED_URL = "core/receivedReceipt";
export const RECEIVED_GUEST_URL = "core/receivedReceipt/guest";
export const RECEIVED_ERROR_GUEST_URL = "core/receivedReceipt/error/guest";
export const DELIVERY_URL = "core/deliveryReceipt";
export const UPLOAD_IMG_URL = "core/upload";
export const ROLE_URL = "account/api/roles";
export const ROLE_URL1 = "account/api/roles/brief";
export const IMAGE_UPLOAD = "core/upload/images";
export const CDN_URL = env.CDN_URL;
export const ENUMDATA_URL = "core/common/enum";
export const DASHBOARD_URL = "core/summary";
export const REPORT_URL = "core/report";
export const PAYMENT_REPORT = "core/report/debt";
export const FINALDEBT_URL = "core/debt";
export const NOERROR_URL = "core/fsapp";
export const GENERAL_URL = "core/general";
export const IMPORT_FILE = "core/import";
export const FILE = "core/files/export";
export const SCHEDULE_DRIVER = "core/schedule/driver";
export const HISTORY_URL = "core/history"
export const BEGINNINGDEBT_URL = "core/beginningDebt"
export const WORKER_CONTRACT = "hrm/contract"
export const WORKER_CONTRACT_UPLOAD = "hrm/upload/contract"
export const WORKER_CONTRACT_EXPORT = "hrm/contract/export"
export const STAFF_META_DATA = "hrm/staff-meta-data"
export const EXPORT_META_DATA = "hrm/report/export"
export const HISTORY_TIMEKEEPING = "hrm/timekeeping/history"
export const TIMEKEEPING = "hrm/timekeeping"
export const OTHER_CONFIG = "hrm/location"
export const CONFIG_HOLIDAY_URL = "hrm/holiday-schedule"
export const WORKING_SHIFT_URL = "hrm/shift-for-staff"
export const OPS_PORTAL_URL = env.OPS_PORTAL_URL